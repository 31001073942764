import axiosInstance from '..'

const getCategories = () => {
  return axiosInstance
    .get('products/anotherproducts/getcategories')
    .then((res) => {
      return res.data
    })
}

const getAccessories = () => {
  return axiosInstance.get('products/anotherproducts/').then((res) => {
    return res.data
  })
}

const getAccessoriesByCategory = (slug: string) => {
  return axiosInstance.get(`products/anotherproducts/${slug}`).then((res) => {
    return res.data
  })
}

export const accessories = {
  getCategories,
  getAccessories,
  getAccessoriesByCategory,
}
