import axiosInstance from '..'

const getCategories = () => {
  return axiosInstance
    .get('/products/garage-door/getcategories')
    .then((res) => {
      return res.data
    })
}

const getCars = () => {
  return axiosInstance.get('/products/garage-door/').then((res) => {
    return res.data
  })
}

const getCarsByCategory = (slug: string) => {
  return axiosInstance.get(`/products/garage-door/${slug}`).then((res) => {
    return res.data
  })
}

const getCarDetails = (slug: string) => {
  return axiosInstance.get(`/products/garage-door/cat/${slug}`).then((res) => {
    return res.data
  })
}

const getCarSimilars = (id: number) => {
  return axiosInstance
    .get(`/products/garage-door/getsimilars/${id}`)
    .then((res) => {
      return res.data
    })
}

export const cars = {
  getCategories,
  getCars,
  getCarsByCategory,
  getCarDetails,
  getCarSimilars,
}
