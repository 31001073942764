import Header from './components/layout/Header'
import GlobalStyle from './components/layout/GlobalStyles'
import { lightTheme } from 'consts/theme'
import React, { useEffect, useState } from 'react'
import Routes from 'routes'
import { ThemeProvider } from 'styled-components'
import './assets/scss/index.scss'
import Footer from './components/layout/Footer'
import ScrollToTop from 'services/hooks/scroltop'
import { initGA, trackPageView } from './analytics';
import { useLocation } from 'react-router-dom'

const TrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};


const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false)
  const [lang, setLang] = useState<string>('en')

  const toggleTheme = () => {
    setIsDarkMode((prevMode: boolean) => !prevMode)
  }

  useEffect(() => {
    initGA(); // Initialize GA when the app loads
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <TrackPage />
      <Header
        dark={isDarkMode}
        lang={lang}
        setLang={setLang}
        toggleTheme={toggleTheme}
      />
      <ScrollToTop />
      <GlobalStyle />
      <Routes lang={lang} />
      <Footer />
    </ThemeProvider>
  )
}

export default App
