import axiosInstance from '..'

const getCategories = () => {
  return axiosInstance
    .get('products/attachable-attributes/getcategories')
    .then((res) => {
      return res.data
    })
}

const getMopeds = () => {
  return axiosInstance.get('products/attachable-attributes/').then((res) => {
    return res.data
  })
}

const getMopedsByCategory = (slug: string) => {
  return axiosInstance
    .get(`products/attachable-attributes/${slug}`)
    .then((res) => {
      return res.data
    })
}

export const mopeds = {
  getCategories,
  getMopeds,
  getMopedsByCategory,
}
