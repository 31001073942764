import axiosInstance from '..'

const getNewsList = () => {
  return axiosInstance.get('news-data').then((res) => {
    return res.data
  })
}

export const news = {
  getNewsList: getNewsList,
}
