import { useLocation } from 'react-router-dom'

export const useCanonicalUrl = () => {
  const location = useLocation()
  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location
    return `${protocol}//${hostname}${port ? `:${port}` : ''}`
  }
  const baseUrl = getBaseUrl()
  const canonicalUrl = `${baseUrl}${location.pathname}${location.search}`

  return canonicalUrl
}
